/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = {
	locale: [],
	localCountryList: [],
	entityLanguageList: [],
	sectionFailKey: false
};
const commonSlice = createSlice({
	name: "common",
	initialState,
	reducers: {
		localeList: (state, action) => {
			state.locale = action.payload;
		},
        countryList: (state, action) => {
			state.localCountryList = action.payload;
		},
		storelanguageList: (state, action) =>{
			state.entityLanguageList = action.payload
		},
		setSectionFailKey: (state, action) => {
			state.sectionFailKey = action.payload
		}
	},
    extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return commonSlice.initialState;
		});
	  },

});

export const {
	localeList,
	countryList,
	storelanguageList,
	setSectionFailKey
} = commonSlice.actions;
export default commonSlice.reducer;
