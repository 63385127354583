export const initialStateAU = {
    state: "",
    cardNumber: "",
    licenseNumber: "",
    passportNumber: "",
    passportCountry: "",
    medicareNumber: "",
    referenceNumber: "",
    medicareCard: "",
    expiryYear: "",
    expiryMonth: "",
    expiryDay:"",
    medicare:"",
    stateObject:"",
    medicareObject:"",
    referenceNumberObject:"",
    mediNumber:""
  };

  export const initalShowIconStateAU ={
    state: false,
    cardNumber: false,
    licenseNumber: false,
    passportNumber:false,
    passportCountry: false,
    medicareNumber: false,
    referenceNumber: false,
    medicareCard: false,
    expiryYear: false,
    expiryMonth: false,
    expiryDay:false,
    medicare:false,
    mediNumber:false
  }
  export const mediCareErrorInitialState ={
    mediNumber:false,
    referenceNumber:false,
    expiryYear:false,
    expiryMonth:false,
    expiryDay:false
  }
  export const passportErrorInitialState = {
    passportCountry:false,
    passportNumber:false
  }
  export const driverLicenseErrorInitialState = {
    state: false,
    cardNumber: false,
    licenseNumber: false,
  }
  export const minMaxInitialState = {
      min:null,
      max:null,
      fieldName:''
  }