/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Cookies from "js-cookie";
 
const ProtectedRoute = ({ children }) => {
    const { user } = useSelector((state) => state.user);
    if (Cookies.get("token")) {
        if (user?.authToken) {
            return <>{children}</>;
        } else {
            return <Loader />
        }
    }
    else {
        return <Navigate to="/login" />;
    }
};
 
export default ProtectedRoute;
 
