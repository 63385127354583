import React, { useState, useEffect } from "react";
import UserMessage1 from "../../../components/UserMessage1";
import { Email } from "../../../constants/ImageConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { configurationServices } from "../../../constants/configurationServices";
import { removeForgotPass } from "../../../reducers/signUpSlice";
import { useDispatch, useSelector } from "react-redux";
const ForgotPasswordConfirm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [dataPassword, setDataPassword] = useState("");
	const { forgotPass } = useSelector((state) => state.signUp);

	const fetchPasswordConfirmData = () => {
		fetch(configurationServices().UserMessage)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setDataPassword(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		fetchPasswordConfirmData();
		
	}, []);

	const handleClick = () => {
		dispatch(removeForgotPass(forgotPass));
		navigate("/login");
	};

	return (
		<UserMessage1
			image={Email}
			title={dataPassword.title}
			description={dataPassword.description}
			buttonText={dataPassword.buttonText}
			handleClick={handleClick}
			email={location?.state?.email}
		/>
	);
};

export default ForgotPasswordConfirm;
