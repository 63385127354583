/* istanbul ignore file */
import axios from "axios";
import store from "../store";
import { resetAll } from "../reducers/resetSlice";
import Cookies from 'js-cookie';

export const customAxios = axios.create();
customAxios.interceptors.request.use(request => {
	if (request?.url?.includes("/signup"))
		request.startTime = performance?.now(); // Record start time
	return request;
});

customAxios.interceptors.response.use((response) => {
	let requestURL = response?.request?.responseURL;
	if (requestURL?.includes("/signup")) {
		const endTime = performance?.now();
		const duration = endTime - response?.config?.startTime;
		console.log(`API - (${requestURL}) response time ---------> ${duration?.toFixed(2)} ms`);
	}
	if (!requestURL.includes("/login") && response?.data?.httpStatus === "UNAUTHORIZED") {
		Cookies.remove('token');
		localStorage.clear();
		store.dispatch(resetAll());
		window.location.replace("/login");
	} else {
		return response;
	}
}, (error) => {
	let requestURL = error?.request?.responseURL;
	if (!requestURL.includes("/login") && (error?.response?.status === 401 || error?.response?.data?.httpStatus === "UNAUTHORIZED")) { 
		Cookies.remove("token");
		localStorage.clear();
		store.dispatch(resetAll());
		window.location.replace("/login");
	}
	return Promise.reject(error);

});
