/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = { autoRejection: {} }; /* istanbul ignore next */
/* istanbul ignore next */
const autoRejectionSlice = createSlice({
	name: "autoRejection",
	initialState,
	reducers: {
		autoRejectionData: (state, action) => {
			/* istanbul ignore next */
			state.autoRejection = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
			/* istanbul ignore next */
		  return autoRejectionSlice.getInitialState;
		});
	  },
});

export const { autoRejectionData } = autoRejectionSlice.actions; /* istanbul ignore next */
export default autoRejectionSlice.reducer;
