/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = { hrinTigger: null };

const hrinTiggerSlice = createSlice({
	name: "hrinTigger",
	initialState,
	reducers: {
		hrinTigger: (state, action) => {
			state.hrinTigger = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return hrinTiggerSlice.getInitialState;
		});
	  },
});

export const { hrinTigger } = hrinTiggerSlice.actions;
export default hrinTiggerSlice.reducer;
