/* istanbul ignore file */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

let lang = localStorage.getItem("i18nextLng");
if (!lang) {
	lang = "en-US";
}

let cdnURL;
const languagePath = "/locales/{{lng}}/{{ns}}.json";
let node_env = process.env.NODE_ENV;
if (!node_env || node_env === "development") {
	cdnURL = languagePath;
	//cdnURL = process.env.REACT_APP_CDN_URL + languagePath;
} else {
	cdnURL = process.env.REACT_APP_CDN_URL + languagePath;
}

i18n
	.use(Backend)
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: cdnURL,
		},
		fallbackLng: "en-US",
		lng: lang,
		// debug: true,
		ns: "language",
		defaultNS: "language",
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
