import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import "../../Drawer/style.scss";
import PropTypes from 'prop-types'

export default function FXCMDrawer({
  content,
  className,
  buttontext,
  logoutIcon,
}) {
  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown") {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="drawer-class"
    >
      {content}
    </Box>
  );

  return (
    <div className="drawer-button">
      <Fragment key="bottom">
        <Button
          onClick={(event) => {
            toggleDrawer("bottom", true)(event);            
          }}
          className={className}
        >
          {buttontext}
          {logoutIcon && (
            <span
              className="material-symbols-outlined"
              aria-label="Exit to App"
            >
              exit_to_app
            </span>
          )}
        </Button>
        <SwipeableDrawer
          sx={{ zIndex: 9999 }}
          anchor="bottom"
          open={state["bottom"]}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
          className="Drawer-Swipeable"
        >
          {list("bottom")}
        </SwipeableDrawer>
      </Fragment>
    </div>
  );
}

FXCMDrawer.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  buttontext: PropTypes.string,
  logoutIcon: PropTypes.string,
}
