/* istanbul ignore file */
import { BASE_URL } from "../constants/payments/api";
import { customAxios } from "../services/serviceInterceptor";
import { v4 as uuidv4 } from 'uuid';

export const getUserDetails = async (authToken, pageName) => {
	const config = {
		...getHeaders(authToken, null)
	};
	try {
		const res = await customAxios.post(
			BASE_URL,
			{
				processName: "userDetails",
				requestBody: {},
				methodType: "POST",
				pageName: pageName
			},
			config
		);
		const result = await res?.data;
		return result;
	} catch (error) {
	}
};

export const getHeaders = (authToken,user,extraParams = {"Content-Type": "application/json"}) => {
	const timeStamp = new Date().getTime();
	let uuidNo = uuidv4();
	let uuidNumber = `${uuidNo}-${timeStamp}`;
	let finalrequestId = uuidNumber.replace(/-/g, "");
	 return {
		headers: {
			Authorization: `Bearer ${authToken}`,
			 requestid: finalrequestId,
			...extraParams
		} 
	};
}
export const getRequestHeaders = () => {
	const timeStamp = new Date().getTime();
	let uuidNo = uuidv4();
	let uuidNumber = `${uuidNo}-${timeStamp}`;
	let finalrequestId = uuidNumber.replace(/-/g, "");
	 return {
		headers: {
			 requestid: finalrequestId
		} 
	};
}



