/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Trans } from "react-i18next";
import "./style.scss";

const FXCMTrans = (props) => {
	return (
		<Trans
			{...props}
			components={{
				span: <span />,
				u: <u />,
				b: <b />,
				ul: <ul />,
				li: <li />,
				a: <a /> //NOSONAR
			}}
		/>
	);
};

export default FXCMTrans;
