/* istanbul ignore file */
export const getDomainPart = (domainName) => {
    const parts = domainName?.split('.'); // Split the domain by periods
    let lastTwoParts = "";
    if (parts?.length >= 2) {
        lastTwoParts = parts?.slice(-2).join('.');
    }
    return lastTwoParts;
};

export const isLocalhost = (url) => {
  return url?.includes('localhost') || url?.includes('127.0.0.1');
}

export const getAPIEndpoint = () => {
    const changeDomain = process.env.REACT_APP_DOMAIN_CHANGE;
    const endPoint = process.env.REACT_APP_DOMAIN_ENDPOINT;
    const currentDomain = window.location.origin;
    const appURL = (changeDomain && changeDomain === "true" && !isLocalhost(currentDomain))
        ? process.env.REACT_APP_URL.replace(getDomainPart(endPoint), getDomainPart(currentDomain))
        : process.env.REACT_APP_URL;
    return appURL;
};

export const getGeoLocationEndpoint = () => {
    const changeDomain = process.env.REACT_APP_DOMAIN_CHANGE;
    const currentDomain = window.location.origin;
    let geoEndPoint = "";
    if (changeDomain && changeDomain === "true" && !isLocalhost(currentDomain)) {
        geoEndPoint = currentDomain + '/geo.js?format=json';
    } else { 
        geoEndPoint = process.env.REACT_APP_GEOLOCATION_API;
    }
    return geoEndPoint;

};

export const getAPIPath = () => {
    return {
        REACT_APP_URL                   :getAPIEndpoint(),
        REACT_APP_BASE_URL              :getAPIEndpoint() + '/process',
        REACT_APP_SIGNUP_URL            :getAPIEndpoint() + '/signup',
        REACT_APP_LOGIN_URL             :getAPIEndpoint() + '/login',
        REACT_APP_PAYMENT_OPTIONS_URL   :getAPIEndpoint() + '/payment-options',
        REACT_APP_PAYMENT_HANDLE_URL    :getAPIEndpoint() + '/payment-handle',
        REACT_APP_POST_PAYMENT_DATA     :getAPIEndpoint() + '/postPaymentData',
        REACT_APP_UPDETE_EVENT          :getAPIEndpoint() + '/update-event'
    }
};



