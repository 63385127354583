import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./style.scss";
import { Box } from "@mui/material";
import {
  notificationIcon,
  ClosePopUp,
} from "../../../constants/ImageConstants";
import PropTypes from 'prop-types'

export default function FXCMPopUp ( props )
{
  const {
  disableButton,
  className,
  variant,
  popUpTitle,
  buttontext,
  logoutIcon,
  popupSubtitle,
  handleLogout,
  logout,
} = props

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
		<div>
			<Button
				onClick={handleClickOpen}
				disabled={disableButton}
				className={className}
				variant={variant}
				data-testid="buttonText"
			>
				{buttontext}
				{logoutIcon && (
					<span className="material-symbols-outlined">exit_to_app</span>
				)}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="popup-class"
			>
				<Box className="notification-Icon">
					<img src={notificationIcon} alt="notifiIcon" />
					<img
						src={ClosePopUp}
						className="closePopIcon"
						alt="notifiIcon"
						onClick={handleClose}
					  data-testid='Icon'
					/>
				</Box>
				<DialogTitle id="alert-dialog-title" className="popup-title">
					{popUpTitle}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						className="subtext-cards"
						id="alert-dialog-description"
					>
						{popupSubtitle}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleLogout} data-testid="cancel-btn">
						{logout}
					</Button>
					<Button variant="contained" onClick={handleClose} autoFocus data-testid="continue-btn">
						{props.continue}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
FXCMPopUp.propTypes = {
  disableButton: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  popUpTitle: PropTypes.string,
  buttontext: PropTypes.string,
  logoutIcon: PropTypes.string,
  popupSubtitle: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  logout: PropTypes.string.isRequired,
  continue: PropTypes.string.isRequired
}
