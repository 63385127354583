/* istanbul ignore file */
import { ipAddress, currentCountry } from "../reducers/ipAddressSlice";


export const ipAddressSave = (data) => async (dispatch) => {
	dispatch(ipAddress(data));
};

export const currentCountrySave = (data) => async (dispatch) => {
	dispatch(currentCountry(data));
};