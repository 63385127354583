/* istanbul ignore file */
import { store } from "../store";
import { configurationServices } from "../constants/configurationServices";
const state = store.getState();

export const isUserLoggedIn = () => {
	let isLoggedIn = false;
	if (state && state.signUp && state.signUp.authRes) {//NOSONAR
		isLoggedIn = true;
	}
	return isLoggedIn;
};

export const getCurrentUrlPath = () => {
	return window.location.pathname;
};

export const getFormattedPKAQuestions = (apiResponse) => {
	let formattedData = [];

	const pkainfo =
		apiResponse && //NOSONAR
		apiResponse["pka-response"] &&
		apiResponse["pka-response"].pkainfo
			? apiResponse["pka-response"].pkainfo
			: null;
	let questionsAndOptionsList =
		pkainfo && pkainfo.questionsAndOptionsList //NOSONAR
			? pkainfo.questionsAndOptionsList
			: [];
	let allDynamicLabels = {};
	questionsAndOptionsList.forEach((pkaQuestion) => {
		let questioData = {
			header: Object.keys(pkaQuestion.questions)[0],
			dynamicQuestionLabel: Object.values(pkaQuestion.questions)[0],
			options: [],
		};

		Object.keys(pkaQuestion.options).forEach((option) => {
			let optionObj = {
				label: pkaQuestion.options[option],
				value: option,
			};
			questioData.options.push(optionObj);
		});
		allDynamicLabels = {
			...allDynamicLabels,
			...pkaQuestion.questions,
			...pkaQuestion.options,
		};
		formattedData.push(questioData);
	});

	return { formattedData, allDynamicLabels };
};

export const getSortedLocaleAsEnglish = () => {
	fetchJson();
};

const fetchJson = async () => {
	try {
		const response = await fetch(configurationServices().en_labelsList);
		const data = await response.json();

		const localLanguageJsonResponse = await fetch(
			configurationServices().BG_labelsList
		);
		const localLanguageJson = await localLanguageJsonResponse.json();

		let localeObject = {};
		Object.keys(data).forEach((key) => {
			if (key in localLanguageJson) {
				localeObject[key] = localLanguageJson[key];
			}
		});
	} catch (error) {
		console.log("Error in reading english file");
	}
};
