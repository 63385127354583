/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = { ip: null, currentCountry: null };

const ipAddressSlice = createSlice({
	name: "ipAddress",
	initialState,
	reducers: {
		ipAddress: (state, action) => {
			state.ip = action.payload;
		},
		currentCountry: (state, action) => {
			state.currentCountry = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return ipAddressSlice.getInitialState;
		});
	  },
});

export const { ipAddress, currentCountry } = ipAddressSlice.actions;
export default ipAddressSlice.reducer;
