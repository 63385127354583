/* eslint-disable array-callback-return */
import { configurationServices } from "../../constants/configurationServices";
import i18next from "i18next";
import { store } from "../../store";


const trackEvent = (eventName, eventDetails) => {
    if (typeof window.__fxcmGtm?.trackEvent === "function") {
        window.__fxcmGtm?.trackEvent(eventName, eventDetails);
    } else {
        console.log("FXCM GTM -- TrackEvent not exposed as function");
    }
}
    let setValue
    // eslint-disable-next-line no-unused-vars
    const config = fetch(configurationServices().analyticsList)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        setValue = data;
        return data;
    })
    
    let countryData;
    let countryDesc;
    let countriesList = configurationServices().countryList;
    fetch(countriesList)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            countryData = data;
            return data;
        });
    
const financialValueMap = [{k:'ESTIMATE_LESS25',v:'<25K'},{k:'ESTIMATE_25_49',v:'25K-50K'},{k:'ESTIMATE_25_50',v:'25K-50K'},{k:'ESTIMATE_50_1LAKH',v:'50k-100K'},{k:'ESTIMATE_1LAKH_2_5LAKH',v:'100K-250K'},{k:'ESTIMATE_2_5lakh_1MILLION',v:'250K-1M'},{k:'ESTIMATE_MORE_1MILLION',v:'>1M'}]

const getFinancialValue = (input)=>{
   let filteredItem =  financialValueMap.filter(item=>{//NOSONAR
        if(item.k===input){
            return item;
        }  
    });

    return filteredItem[0]?.v;
};

const trackSingleEvent=({eventName,eventDetails})=>{
    if (!window.dataLayer.find(item => (item.eventName === eventName)))
        window.__fxcmGtm?.trackEvent(eventName, eventDetails);
}

const trackOnloadEvent = (eventName, eventDetails) => {
    if (!window.dataLayer.find(item => (item.eventName === eventName)))
    window.__fxcmGtm?.trackEvent(eventName, eventDetails);
}

const trackDynamicPageEvent = (sectionResponse,additionalData) => { 
    const state = store.getState();
    const { getAllEnLabels } = state?.signUp || {};
    if (sectionResponse) {
        let obj = [];
        if (sectionResponse.hasOwnProperty('aboutYourself')) {
            if (sectionResponse['aboutYourself'].hasOwnProperty('CITIZENSHIP_COUNTRY')) {
                if (!window.dataLayer.find(item => (item.engagementContext === 'No' || item.engagementContext === 'Yes'))) {
                    window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", {onboardingStep: "Step 6", engagementContext: setValue.Residency_citizenship });
                }
            }
            if (sectionResponse['aboutYourself'].hasOwnProperty('placeOfBirth')) {
                if (sectionResponse['aboutYourself'].ischecked) {
                    if (countryData) {
                        countryData.map(el => {
                            if (el.country_code === sectionResponse['aboutYourself']?.citizenship) {
                                countryDesc = el.country_desc
                            }
                        });
                    }
                    window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 7", formCountry: countryDesc, engagementContext: setValue.CITIZENSHIP });
                } else {
                    if (!window.dataLayer?.find(item => (item.onboardingStep === 'Step 8')))
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 8", engagementContext: setValue.PLACE_OF_BIRTH });
                }
            }
            if (sectionResponse['aboutYourself'].hasOwnProperty('politicallyExposedPerson')){
                if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 9'))) {
                    if (typeof sectionResponse['aboutYourself'].politicallyExposedPerson == "boolean") {
                        if (sectionResponse['aboutYourself'].politicallyExposedPerson === true) {
                            window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 9", politicallyExposed: "Yes", engagementContext: setValue.POLITICALLY_EXPOSED_PERSON});
                        } else {
                            window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 9", politicallyExposed: "No", engagementContext: setValue.POLITICALLY_EXPOSED_PERSON});
                        }
                    }
                }
            }
        }
        if (sectionResponse.hasOwnProperty('CITIZENSHIP_COUNTRY')) {
            if (!window.dataLayer.find(item => (item.engagementContext === 'No' || item.engagementContext === 'Yes'))) {
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", {onboardingStep: "Step 6", engagementContext: setValue.Residency_citizenship });
            }
        }
        if (sectionResponse.hasOwnProperty('citizenship')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 7'))){
                if (countryData) {
                    countryData.map(el => {
                        if (el.country_code === sectionResponse['values']?.citizenship || el.country_code === sectionResponse?.citizenship || el.country_code === sectionResponse['aboutYourself']?.citizenship) {
                            countryDesc = el.country_desc
                        }
                    });
                }
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 7", formCountry: countryDesc,  engagementContext: setValue.CITIZENSHIP});
            }
        }
        if (sectionResponse.hasOwnProperty('placeOfBirth')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 8')))
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 8",engagementContext:setValue.PLACE_OF_BIRTH});
        }
        if (sectionResponse.hasOwnProperty('politicallyExposedPerson')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 9'))) {
                if (typeof sectionResponse.politicallyExposedPerson == "boolean") {
                    if (sectionResponse.politicallyExposedPerson === true) {
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 9", politicallyExposed: "Yes", engagementContext: setValue.POLITICALLY_EXPOSED_PERSON});
                    } else {
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 9", politicallyExposed: "No", engagementContext: setValue.POLITICALLY_EXPOSED_PERSON});
                    }
                }
            }
        }
        if (sectionResponse.hasOwnProperty('postal_code')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 11')))
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 11",engagementContext: setValue.ADDRESS});
        }
        if(sectionResponse.hasOwnProperty('employmentInfo')){
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 13'))){
                let translated = i18next.t(getAllEnLabels[sectionResponse['employmentInfo'].employmentStatus]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { employmentStatus: translated, onboardingStep: "Step 13",engagementContext: setValue.EMPLOYMENT_STATUS });
            }
            if (sectionResponse['employmentInfo'].hasOwnProperty('position')) {
                if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 15'))){
                    let translated = i18next.t(getAllEnLabels[sectionResponse['employmentInfo'].position]);
                    window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { role: translated, onboardingStep: "Step 15", engagementContext: setValue.YOUR_ROLE});
                }
                
                    
            }
        }
        if (sectionResponse.hasOwnProperty('employmentStatus')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 13'))){
                let translated = i18next.t(getAllEnLabels[sectionResponse['employmentStatus']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { employmentStatus: translated, onboardingStep: "Step 13", engagementContext: setValue.EMPLOYMENT_STATUS});
            }
            else{
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 13'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 13')) {
                            if (el.employmentStatus !== undefined) {
                                obj.push(el.employmentStatus);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['employmentStatus'])) {
                        let translated = i18next.t(getAllEnLabels[sectionResponse['employmentStatus']]);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { employmentStatus: translated, onboardingStep: "Step 13" });
                    }
                }
            }
            obj = [];
        }
        if (sectionResponse.hasOwnProperty('natureOfBusiness')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 14'))) {
                let translated = i18next.t(getAllEnLabels[sectionResponse['natureOfBusiness']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { natureOfBusiness: translated, onboardingStep: "Step 14", engagementContext: setValue.YOUR_INDUSTRY});
            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 14'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 14')) {
                            if (el.natureOfBusiness !== undefined) {
                                obj.push(el.natureOfBusiness);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['natureOfBusiness'])) {
                        let translated = i18next.t(getAllEnLabels[sectionResponse['natureOfBusiness']]);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { natureOfBusiness: translated, onboardingStep: "Step 14" });
                    }
                }
            }
            obj =[];
        }
        if (sectionResponse.hasOwnProperty('position')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 15'))){
                let translated = i18next.t(getAllEnLabels[sectionResponse['position']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { role: translated, onboardingStep: "Step 15",engagementContext: setValue.YOUR_ROLE});
            }
                
        }
        if (sectionResponse.hasOwnProperty('totalAnnualIncome')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 16'))){
                let filteredValue = getFinancialValue(sectionResponse['totalAnnualIncome']);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { incomeBucket: filteredValue, onboardingStep: "Step 16",engagementContext: setValue.ANNUAL_INCOME});
            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 16'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 16')) {
                            if(el.incomeBucket !== undefined){
                                obj.push(el.incomeBucket);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['totalAnnualIncome'])) {
                        let filteredValue = getFinancialValue(sectionResponse['totalAnnualIncome']);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { incomeBucket: filteredValue, onboardingStep: "Step 16" });
                    }
                }
            }
            obj =[];
        }
        if (sectionResponse.hasOwnProperty('netWorth')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 17'))){
                let filteredValue = getFinancialValue(sectionResponse['netWorth']);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { estimatedWealth:filteredValue, onboardingStep: "Step 17", engagementContext: setValue.ESTIMATED_WEALTH});
            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 17'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 17')) {
                            if (el.estimatedWealth !== undefined) {
                                obj.push(el.estimatedWealth);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['netWorth'])) {
                        let filteredValue = getFinancialValue(sectionResponse['netWorth']);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { estimatedWealth: filteredValue, onboardingStep: "Step 17" });
                    }
                }
            }
            obj =[];
        }
        if (sectionResponse.hasOwnProperty('sourceOfWealth')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 18'))){
                let translated = i18next.t(getAllEnLabels[sectionResponse['sourceOfWealth']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { sourceOfWealth: translated, onboardingStep: "Step 18", engagementContext: setValue.SOURCE_OF_WEALTH});
            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 18'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 18')) {
                            if(el.sourceOfWealth !==undefined){
                                obj.push(el.sourceOfWealth);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['sourceOfWealth'])) {
                        let translated = i18next.t(getAllEnLabels[sectionResponse['sourceOfWealth']]);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { sourceOfWealth: translated, onboardingStep: "Step 18" });
                    }
                }
            }
            obj = [];
        }
        if (sectionResponse.hasOwnProperty('sourceOfTradingFund')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 19'))){
                let translated = i18next.t(getAllEnLabels[sectionResponse['sourceOfTradingFund']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { sourceOfTradingFund: translated, onboardingStep: "Step 19", engagementContext: setValue.TRADING_FUNDS});
            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 19'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 19')) {
                            if(el.sourceOfTradingFund !== undefined){
                                obj.push(el.sourceOfTradingFund);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['sourceOfTradingFund'])) {
                        let translated = i18next.t(getAllEnLabels[sectionResponse['sourceOfTradingFund']]);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { sourceOfTradingFund: translated, onboardingStep: "Step 19" });
                    }
                }
            }
            obj = [];
        }
        if(sectionResponse.hasOwnProperty('financialInfo')){
            if (sectionResponse['financialInfo'].hasOwnProperty('intendToFund')) {
                if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 20'))){
                    let translated = i18next.t(getAllEnLabels[sectionResponse['financialInfo'].intendToFund]);
                    window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { intendToFund: translated.replaceAll(/{{currency}}/g,additionalData.currency), onboardingStep: "Step 20", engagementContext:setValue.ANNUAL_CONTRIBUTION });
                }
            }
        }
        
        if (sectionResponse?.hasOwnProperty('tradingInfo')) {
            if (sectionResponse?.tradingInfo.hasOwnProperty('noOfTradedCFD')) {
                if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 24'))) {
                let translated = i18next.t(getAllEnLabels[sectionResponse?.tradingInfo['noOfTradedCFD']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 24",tradesCFD:translated,engagementContext:setValue.noOfTradedCFD});
                }
            }
        }
        if (sectionResponse.hasOwnProperty('noOfTradedCFD')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 24'))) {
            let translated = i18next.t(getAllEnLabels[sectionResponse['noOfTradedCFD']]);
            window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { onboardingStep: "Step 24",tradesCFD:translated,engagementContext:setValue.noOfTradedCFD});
            }
        }
        if(sectionResponse.hasOwnProperty('purposeOfAccount')){
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 21'))) {
                let translated = i18next.t(getAllEnLabels[sectionResponse['purposeOfAccount']]);
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { purposeOfAccount: translated, onboardingStep: "Step 21",engagementContext: setValue.TRADING});

            }
            else {
                if (window.dataLayer.find(item => (item.onboardingStep === 'Step 21'))) {
                    window.dataLayer.forEach(el => {
                        if ((el.onboardingStep === 'Step 21')) {
                            if(el.purposeOfAccount !== undefined){
                                obj.push(el.purposeOfAccount);
                            }
                        }
                    });
                    if (!obj.includes(sectionResponse['purposeOfAccount'])) {
                        let translated = i18next.t(getAllEnLabels[sectionResponse['purposeOfAccount']]);
                        window.__fxcmGtm?.trackEvent("onboarding_form_step_amend", { purposeOfAccount: translated, onboardingStep: "Step 21" });
                    }
                }
            }
            obj = [];
        }
        if (sectionResponse.hasOwnProperty('tradingInfo')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 22')))
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { tradingNew: sectionResponse['tradingInfo'].newToTrading || sectionResponse['tradingInfo'].newToTrading_AU ? "Yes" : "No", onboardingStep: "Step 22",engagementContext: setValue.NEW_TO_TRADING});
        }
        if (sectionResponse.hasOwnProperty('newToTrading') || sectionResponse.hasOwnProperty('newToTrading_AU')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 22')))
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { tradingNew: sectionResponse['newToTrading']?.newToTrading || sectionResponse['newToTrading_AU']?.newToTrading_AU ? "Yes" : "No", onboardingStep: "Step 22", engagementContext: setValue.NEW_TO_TRADING});
        }
        if (sectionResponse.hasOwnProperty('tradingCfd')) {
            if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 23')))
                window.__fxcmGtm?.trackEvent("onboarding_form_step_submit", { learnt_CFD: sectionResponse['tradingCfd'], onboardingStep: "Step 23", engagementContext:setValue.LEARN_ABOUT_TRADING_CFO});

        }
        if (sectionResponse.hasOwnProperty('rejection')) {
            if (!window.dataLayer?.find(item => (item.eventName === 'onboarding_form_declined')))
                window.__fxcmGtm?.trackEvent("onboarding_form_declined", { engagementContext: setValue.Applicaton_Rejected, Rejection_reason: sectionResponse.rejection });
        }
    }
}
const trackDocumentEvents = (obj) => { // NOSONAR
    if (obj.action === 'docUploadPurpose') {
        if (obj.type === "Identity") {
                window.__fxcmGtm?.trackEvent("document_upload_start", { uploadPurpose: obj.type });
        }
        if (obj.type === "Address") {
                window.__fxcmGtm?.trackEvent("document_upload_start", { uploadPurpose: obj.type });
        }
        if (obj.type === "SOW") {
                window.__fxcmGtm?.trackEvent("document_upload_start", {uploadPurpose: obj.type });
        }
        if (obj.type === "SOF") {
                window.__fxcmGtm?.trackEvent("document_upload_start", {uploadPurpose: obj.type });
        }
    }
    if (obj.action === 'docUploadComplete') {
        if (obj.type === "Identity") {
                window.__fxcmGtm?.trackEvent("document_upload_complete", {});
        }
        if (obj.type === "Address") {
                window.__fxcmGtm?.trackEvent("document_upload_complete", {});
        }
        if (obj.type === "SOW") {
                window.__fxcmGtm?.trackEvent("document_upload_complete", {});
        }
        if (obj.type === "SOF") {
                window.__fxcmGtm?.trackEvent("document_upload_complete", {});
        }
    }
    if (obj.action === 'docUploadRejected') {
        window.__fxcmGtm?.trackEvent("document_upload_rejected", {});
    }
    if(obj.action === 'documentUploadPassed'){
        window.__fxcmGtm?.trackEvent("document_upload_passed", {});
    }
}

const trackFundingCompleteEvent=()=>{
    if (!window.dataLayer.find(item => (item.onboardingStep === 'Step 39')))
        window.__fxcmGtm?.trackEvent("deposit_complete", { onboardingStep: "Step 39" });

}

const analytics = {
    trackEvent: (eventName, eventDetails) => trackEvent(eventName, eventDetails),
    trackDynamicPageEvent: (sectionResponse,additionalData) => trackDynamicPageEvent(sectionResponse,additionalData),
    trackDocumentEvents: (obj) => trackDocumentEvents(obj),
    trackSingleEvent:(data) => trackSingleEvent(data),
    trackOnloadEvent: (eventName, eventDetails) => trackOnloadEvent(eventName, eventDetails),
    trackFundingCompleteEvent:trackFundingCompleteEvent
};

export { analytics };