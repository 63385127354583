import { useState, useEffect } from "react";

const useDirectionHook = (languageParam) => {
  const [direction, setDirection] = useState({ status: false, value: "ltr" }); //ideal state

  useEffect(() => {
    if (languageParam === "ar-AE") {
      setDirection({ status: true, value: "rtl" });
    }
  }, [languageParam]);

  return [direction];
};

export default useDirectionHook;
