/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore file */
import React, { useState, useEffect } from 'react'
import { Box, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
	setUserCurrentLocation,
	setUserEntity
} from '../../../reducers/signUpSlice'
import { useDispatch, useSelector } from 'react-redux'
import { configurationServices } from '../../../constants/configurationServices'
import {FXCMAutoCompleted} from './FXCMAutoCompleted'
import useDirectionHook from '../../../hooks/useDirectionHook'
import './style.scss'
import PropTypes from 'prop-types'
import { PassportAutoComplete } from '../../../utils/PassportAutoComplete';
import FXCMTrans from '../FXCMTrans'

const FXCMAutoComplete = props => {
	const { t: getLabel } = useTranslation()
	const dispatch = useDispatch()

	const { languageParam, userEntity } = useSelector(state => state?.signUp)
	const [direction] = useDirectionHook(languageParam) //custom hook
	const [countryList, setCountryList] = useState()

	let {
		options,
		label,
		handleClick,
		// cssStyles,
		textFieldSize,
		errorMessage,
		restrictedCountries,
		error,
		currentLocation,
		screenType,
		preSelectedLocation,
    setOpenSelectBox,
    inputRef,
    setError,
    setPassportError
	} = props

	const [selCountry, setSelCountry] = useState(preSelectedLocation)

   /* istanbul ignore next */
	const updateCountryDetails = () => {
		let curentLoc = currentLocation || ''
		let arrayToSort = [...options]
		arrayToSort.sort((x, y) => {
			if (x.country_code === curentLoc) {
				return -1
			} else if (y.country_code === curentLoc) {
				return 1
			} else {
				return 0
			}
		})

		options = arrayToSort;
		setCountryList(options)
		const preSelectedCountry = options.find(
			item => item.country_code === preSelectedLocation
		)
		if (screenType === 'signup' || screenType === 'auIdCard') {
			const selectedCountry = preSelectedCountry || options[0]
			setSelCountry(selectedCountry)
			handleClick(selectedCountry)
		}

		if (
			options[0] &&
			options[0].country_desc === 'AUSTRIA'
		) {
			dispatch(setUserCurrentLocation('AT'))
		}
	}
  
	 /* istanbul ignore next */
	useEffect(() => {
		if (options) {
			updateCountryDetails();
    }
	}, [options] )
  
   /* istanbul ignore next */
  const onOptionChange = value => {
    if (window.location.pathname === '/') {
      if (value) {
        dispatch(setUserEntity(value.entity_code))
      }
    }
    configurationServices(userEntity)
    handleClick(value)
    setSelCountry(value)
    if (value && value.country_desc === 'AUSTRIA') {
      dispatch(setUserCurrentLocation('AT'))
    }
    error = false
  }

 
  const displayError = () => {
    const traduAppLink = process.env.REACT_APP_TRADU_LINK;
     /* istanbul ignore next */
  if (errorMessage === 'restricted') {
    return(
      <>
        {getLabel('COUNTRY_NOTELIGIBLE', {
          country: getLabel(selCountry.country_desc)
        })}
      </>
    )
  } else if (errorMessage === 'misc') {
    return (<FXCMTrans
      defaults={getLabel("COUNTRY_REDIRECT")}
      values={{
        url: restrictedCountries.miscUrl[selCountry.country_code],
      }}
    ></FXCMTrans>)
  } else if (errorMessage === 'traduRedirect') {
    return (<FXCMTrans
      defaults={getLabel("TRADU_REDIRECT")}
      values={{
        url: traduAppLink,
      }}
    ></FXCMTrans>)
  } else if (errorMessage === 'rb_cor_error') {
    return (<>{getLabel('RB_COR_ERROR')}</>)
  } else {
    return (<>{getLabel('MANDATORY_CHECK')}</>)
  }
}

 /* istanbul ignore next */
  const countryToFlag = country_desc => {
    return options.find(
      country => getLabel(country.country_desc) === country_desc
    )?.image
  }
   /* istanbul ignore next */
  const renderDropdownList = (props, option) => {
    if (screenType === 'signup') {
      return (
        <div className='options_list'>
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img loading='lazy' width='34' src={option.image} alt='' />
            <div className='currentlocationSty'>
              {currentLocation && option.country_code === currentLocation ? (
                <span className='currenLocText'>{getLabel("FXCM_AUTO_COMPLETE_YOUR_CURRENT_LOCATION")}</span>
              ) : (
                ''
              )}
              <span className='countryName'>
                {getLabel(option.country_desc)}
              </span>
            </div>
          </Box>
          {currentLocation && option.country_code === currentLocation ? (
            <div
              className={
                currentLocation && option.country_code === currentLocation
                  ? 'location_divider '
                  : ''
              }
            ></div>
          ) : (
            <Divider />
          )}
          {currentLocation && option.country_code === currentLocation ? (
            <span className='currenLocText p-18'>{getLabel("FXCM_AUTO_COMPLETE_SELECT_COUNTRY")}</span>
          ) : (
            ''
          )}
        </div>
      )
    } else {
      return (
        <>
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img loading='lazy' width='34' src={option.image} alt='' />
            {getLabel(option.country_desc)}
          </Box>
          <Divider />{' '}
        </>
      )
    }
  }
   /* istanbul ignore next */
  if (countryList) {
    return (
      <>
        {screenType === 'auIdCard' ? (
          <PassportAutoComplete
            {...props}
            countryList={countryList}
            onOptionChange={onOptionChange}
            selCountry={selCountry}
            renderDropdownList={renderDropdownList}
            direction={direction}
            countryToFlag={countryToFlag}
            label={label}
            textFieldSize={textFieldSize}
            error={error}
            displayError={displayError}
            options={options}
            setOpenSelectBox={setOpenSelectBox}
            inputRef={inputRef}
            setError={setError}
            setPassportError={setPassportError}
        />
        ) :(
          <FXCMAutoCompleted
            {...props}
            countryList={countryList}
            onOptionChange={onOptionChange}
            selCountry={selCountry}
            renderDropdownList={renderDropdownList}
            direction={direction}
            countryToFlag={countryToFlag}
            label={label}
            textFieldSize={textFieldSize}
            error={error}
            displayError={displayError}
            options={options}
            errorMessage={errorMessage}
          />
        )}
      </>
    )
  } else {
    return null
  }
}
 /* istanbul ignore next */
FXCMAutoComplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      country_code: PropTypes.string.isRequired,
      country_desc: PropTypes.string.isRequired,
      entity_code: PropTypes.string.isRequired,
      entity_desc: PropTypes.string.isRequired,
      phone_prefix: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    })
  ).isRequired,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  cssStyles: PropTypes.object,
  textFieldSize: PropTypes.string,
  errorMessage: PropTypes.string,
  restrictedCountries: PropTypes.object,
  error: PropTypes.bool,
  currentLocation: PropTypes.string,
  screenType: PropTypes.string,
  preSelectedLocation: PropTypes.string
}


export default FXCMAutoComplete
