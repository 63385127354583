/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";

const initialState = {
	error: null,
};

const errorNotifySlice = createSlice({
	name: "apiFail",
	initialState,
	reducers: {
		notifyApiFail: (state, action) => {
			const payload = action.payload;
			state.error = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return errorNotifySlice.getInitialState;
		});
	  },
});

export const { notifyApiFail } = errorNotifySlice.actions;
export default errorNotifySlice.reducer;
