import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import FXCMTrans from "../FXCMTrans";
import "./style.scss";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types'

const FXCMErrorHandling = ( props ) =>
{
  const {
          PaddingTop,
          column,
          image,
          titleCondition,
          title,
          attempsRemaining,
          count,
          subTitleCondition,
          subTitle,
          warning,
} = props
  const { t: getLabel } = useTranslation();
  const { userEntity } = useSelector((state) => state.signUp);
  return (
    <div className={userEntity === "FXCM_EU" || userEntity === "FXCM_UK" ? "error_Container locked_error" : "error_Container"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-testid='error-Box'
      >
        <div className="error-para">
          <Box className={column ? "flex-column error-alert" : "error-alert"} sx={PaddingTop && {pt: "5px"}}>
            <img src={image} alt="errorWarning" />
            <Typography className="heading-error" data-testid='title'>
              {titleCondition ? (
                getLabel(title)
              ) : (
                <FXCMTrans
                  defaults={getLabel(attempsRemaining)}
                  values={{
                    Count: count,
                  }}
                ></FXCMTrans>
              )}
            </Typography>
          </Box>
          <Typography className="subtext-cards" data-testid='subTitle'>
            {subTitleCondition
              ? getLabel(subTitle)
              : getLabel(warning)}
          </Typography>
        </div>
      </Box>
    </div>
  );
};
FXCMErrorHandling.propTypes = {
          PaddingTop: PropTypes.string,
          column: PropTypes.string,
          image: PropTypes.string,
          titleCondition: PropTypes.string,
          title: PropTypes.string,
          attempsRemaining: PropTypes.string,
          count: PropTypes.string,
          subTitleCondition: PropTypes.string,
          subTitle: PropTypes.string,
          warning: PropTypes.string,
}

export default FXCMErrorHandling;
