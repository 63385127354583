import { configureStore } from "@reduxjs/toolkit";
import signUpSlice from "./reducers/signUpSlice";
import userInfoSlice from "./reducers/userInfoSlice";
import quesSaveSlice from "./reducers/quesSaveSlice";
import autoRejectionSlice from "./reducers/autoRejectionSlice";
import thresholdSlice from "./reducers/thresholdSlice";
import ipAddressSlice from "./reducers/ipAddressSlice";
import resetSlice from "./reducers/resetSlice";
import errorNotifySlice from "./reducers/errorNotifySlice";
import hrinTiggerSlice from "./reducers/hrinTiggerSlice";
import commonSlice from "./reducers/commonSlice";

export const persistedReducer = {
	signUp: signUpSlice,
	user: userInfoSlice,
	quesSaveStep: quesSaveSlice,
	autoRejection: autoRejectionSlice,
	thresholdList: thresholdSlice,
	ipAddress: ipAddressSlice,
	apiFail: errorNotifySlice,
	clearStore: resetSlice,
	hrinTigger: hrinTiggerSlice,
	common: commonSlice
};

export const store = configureStore({
	reducer: persistedReducer,
	devTools: false
});

export default store;

