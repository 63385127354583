/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = { activeStep: 0, skippedSections: [],resetSkipp:[] };

const quesSaveSlice = createSlice({
	name: "quesSave",
	initialState,
	reducers: {
		quesSaveInfo: (state, action) => {
			state.activeStep = action.payload;
		},
		skipSectionInfo: (state, action) => {
			state.skippedSections = [...state.skippedSections, action.payload];
		},
		resetSkipp: (state) => {state.skippedSections = initialState.skippedSections}
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return quesSaveSlice.getInitialState;
		});
	  },
});

export const { quesSaveInfo, skipSectionInfo ,resetSkipp} = quesSaveSlice.actions;
export default quesSaveSlice.reducer;
