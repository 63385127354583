/* istanbul ignore file */
import React from 'react';
import { Autocomplete,TextField } from '@mui/material';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import EndAdornment from './EndAdornment';
import { passportErrorInitialState } from '../constants/auInitialState';

export const PassportAutoComplete = ( props ) =>{
    const {
        cssStyles,
        countryList,
        onOptionChange,
        selCountry,
        renderDropdownList,
        direction,
        countryToFlag,
        label,
        textFieldSize,
        error,
        displayError,
        item, 
        openRefSelectBox,
        openSelectBoxItem,
        selectedAUState,
        auInfo,
        openReferenceSelectBox,
        openSelectBox,
        setOpenSelectBox,
        inputRef,
        setError,
        setPassportError
    } = props
    const { t: getLabel } = useTranslation();

  return (
    <div>
        <Autocomplete
            className={cssStyles}
            id='country-select-demo'
            options={countryList}
            getOptionLabel={option => getLabel(option.country_desc)}
            onChange={(event, newValue) => onOptionChange(newValue)}
            value={selCountry}
            open={openSelectBox}
            onClose={() => setOpenSelectBox(false)}
            onOpen={()=>openSelectBoxItem(item.fieldName)}
            renderOption={(props, option) => (
                <div>{renderDropdownList(props, option)}</div>
            )}
            onInputChange={(event, newInputValue, reason) => {
                // NOSONAR
                if (reason === "reset") {
                  auInfo.passportNumber = ""; 
                  setError(false);
                  setPassportError(passportErrorInitialState);
                }
            }}
            renderInput={(props) => {
                return(
                <>
                {props.inputProps.value && (
                    <span
                    className={direction.status ? 'country-flag-arabic' : 'country-flag'}
                    >
                    <img
                        loading='lazy'
                        width='34'
                        src={countryToFlag(props.inputProps.value)}
                        alt=''
                    />
                    </span>
                )}
                <TextField
                    {...props}
                    inputRef={inputRef}
                    sx={{
                    '.MuiInputBase-root': {
                        '&::before': {
                        background: !props.inputProps.value
                            ? `url(${
                                process.env.REACT_APP_CDN_URL + '/images/search-icon.svg'
                            })`
                            : ''
                        }
                    }
                    }}
                    label={label}
                    inputProps={{
                    ...props.inputProps
                    }}
                    size={textFieldSize}
                    error={error}
                    helperText={
                    error ? <div className='displayError'>{displayError()}</div> : ''
                    }
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <EndAdornment 
                                item={item} 
                                openRefSelectBox={openRefSelectBox}
                                openSelectBoxItem={openSelectBoxItem}
                                selectedAUState={selectedAUState}
                                auInfo={auInfo}
                                openReferenceSelectBox={openReferenceSelectBox}
                                openSelectBox={openSelectBox}
                            />
                        ),
                      }}
                   />
                </>
            )}}
        />

    </div>
  )
}
PassportAutoComplete.propTypes = {
  cssStyles: PropTypes.string,
  countryList: PropTypes.string,
  onOptionChange: PropTypes.string,
  selCountry: PropTypes.string,
  renderDropdownList: PropTypes.string,
  direction: PropTypes.string,
  countryToFlag: PropTypes.string,
  label: PropTypes.string,
  textFieldSize: PropTypes.string,
  error: PropTypes.string,
  displayError: PropTypes.string,
}
