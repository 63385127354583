import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { storeEsmaCompleteText } from "../../../reducers/signUpSlice";
import { fetchEsmaBarText } from "../../../services/auth.service";
import FXCMTrans from "../../common/FXCMTrans";
import "./style.scss";
import { warning } from "../../../constants/ImageConstants";

const EsmaBanner = () => {
	const dispatch = useDispatch();
	const { esmaBarResponseData, getUserLocation, userEntity } = useSelector(
		(state) => state.signUp
	);
	const { languageParam } = useSelector((state) => state.signUp);
	const [esmaData, setEsmaData] = useState({});
	const [userLocation, setUserLocation] = useState("");
	const [isExpanded, setIsExpanded] = useState(true);
	const [width, setWidth] = React.useState(window.innerWidth);
	const { t: getLabel } = useTranslation();
	const prevCountRef = useRef();
	useEffect(() => {
		if (prevCountRef.current !== userEntity) {
			if (userEntity === "FXCM_EU" || userEntity === "FXCM_UK") {/* istanbul ignore next */
				prevCountRef.current = userEntity;/* istanbul ignore next */
				dispatch(fetchEsmaBarText(userEntity));
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userEntity]);
	const updateWidthAndHeight = () => {/* istanbul ignore next */
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener("resize", updateWidthAndHeight);
	});
	useEffect(() => {
		if (esmaBarResponseData) {
			const text = getLabel("TOP_NOTIFICATION");
			let esmaText = text.replace(
				"{{percentage}}",
				esmaBarResponseData.profitability_percent
			);
			dispatch(storeEsmaCompleteText(esmaText));
			setEsmaData(esmaBarResponseData);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [esmaBarResponseData]);
	useEffect(() => {
		if (getUserLocation) {/* istanbul ignore next */
			setUserLocation(getUserLocation);
		}
	}, [getUserLocation]);
	const accordianExpanded = () => {/* istanbul ignore next */
		setIsExpanded(!isExpanded);
	};
	const isMobileViewOnly = width <= 900; 
	const displayEsmaBar = () => {
		return (
			<div>
				{isMobileViewOnly ? (
					<div className="top-bar-Notification-mobile">
						<Container>
							<Accordion className="" onChange={accordianExpanded}>
								{isExpanded ? (
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										{userLocation === "AT" ? (
											<img src={warning} alt="passwordIcon" />
										) : (
											""
										)}{" "}
										<p style={{fontSize:"10px"}}>
											<FXCMTrans
												defaults={getLabel("SHORTESMATEXT")}
												values={{
													percentage: esmaData.profitability_percent,
												}}
											></FXCMTrans>
										</p>
									</AccordionSummary>
								) : (
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										{userLocation === "AT" ? (
											<img src={warning} alt="passwordIcon" />
										) : (
											""
										)}
										<p style={{fontSize:"11px"}}>
											<FXCMTrans
												defaults={getLabel("LONGESMATEXT")}
												values={{
													percentage: esmaData.profitability_percent,
												}}
											></FXCMTrans>
										</p>
									</AccordionSummary>
								)}
							</Accordion>{" "}
						</Container>
					</div>
				) : (
						<div className={languageParam === "el-GR" ?"top-bar-Notification-greek":"top-bar-Notification"}>
						<Container>
							{" "}
							{userLocation === "AT" ? (
								<img src={warning} alt="passwordIcon" />
							) : (
								""
							)}
							<p>
								<FXCMTrans
									defaults={getLabel("TOP_NOTIFICATION")}
									values={{ percentage: esmaData.profitability_percent }}
								></FXCMTrans>
							</p>
						</Container>
					</div>
				)}
			</div>
		);
	};
	return <div className="top-bar-container">{displayEsmaBar()}</div>;
};

export default EsmaBanner;
