import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserMessage1 from "../../components/UserMessage1";
import { passwordIcon } from "../../constants/ImageConstants";
import { configurationServices } from "../../constants/configurationServices";
import { removeForgotPass } from "../../reducers/signUpSlice";
import { useDispatch } from "react-redux";

const ResetPasswordConfirmation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [data, setData] = useState("");
	const [email] = useState(localStorage.getItem("usermail"));

	const fetchData = () => {
		fetch(configurationServices().UserMessage)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setData(data);
			})
			.catch((e) => {/* istanbul ignore next */
				console.log(e.message);
			});
	};
	
	useEffect(() => {
		fetchData();
	}, []);

	const handleClick = () => {/* istanbul ignore next */
		dispatch(removeForgotPass());/* istanbul ignore next */
		navigate("/login");
	};

	return (
		<UserMessage1
			image={passwordIcon}
			title={data.reset_confirm_title}
			description={data.reset_confirm_desc}
			buttonText={data.buttonText}
			handleClick={handleClick}
			email={email}
		/>
	);
};

export default ResetPasswordConfirmation;
