/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = { thresholdList: null };

const thresholdSlice = createSlice({
	name: "threshold",
	initialState,
	reducers: {
		thresholdData: (state, action) => {
			state.thresholdList = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return thresholdSlice.initialState;
		});
	  },
});

export const { thresholdData } = thresholdSlice.actions;
export default thresholdSlice.reducer;
