import React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material'
import { notificationIcon, ClosePopUp } from '../../../constants/ImageConstants'
import { useTranslation } from 'react-i18next';
import { setSectionFailKey } from '../../../reducers/commonSlice';
import { useDispatch, useSelector } from "react-redux";

const FXCMGenericAlert = () => {
    const { t: getLabel } = useTranslation();
    const { sectionFailKey } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(setSectionFailKey(false));
        window.location.reload();
    }
    return (
        <Dialog
            open={sectionFailKey}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className='popup-class'
        >
            <Box className='notification-Icon'>
                <img src={notificationIcon} alt='notifiIcon' />
                <img
                    src={ClosePopUp}
                    className='closePopIcon'
                    alt='notificationIcon'
                    onClick={onClose}

                />
            </Box>
            <DialogTitle
                id='alert-dialog-title'
                className='popup-title'
                data-testid='alert-dialog-title1'
            >
                {getLabel("GENERIC_ALERT_TITLE")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    className='subtext-cards'
                    id='alert-dialog-description'
                    data-testid='alert-dialog-description1'
                >
                    {getLabel("GENERIC_ALERT_MESSAGE")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ width: '100%' }}
                    data-testid='logout-button'
                    variant='contained'
                    onClick={onClose}
                >
                    {getLabel("CONTINUE")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default FXCMGenericAlert;
