/* istanbul ignore file */
import { BASE_URL } from "../constants/payments/api";
import { customAxios } from "../services/serviceInterceptor";
import { getHeaders } from "../utils/getUsersDetails";

export const getPaymentStatusByFxowaccount  = async(user,fxcmAccountId,navigate,authToken,showDepositError=false)=>{
    const payLoad = {
        processName: "paymentStatus",
        requestBody: {
            fxcmAccountId,
        },
        method: "POST",
    };
    const config = {
		...getHeaders(authToken,user)
	};
    try {
        const request = await customAxios.post(`${BASE_URL}`, payLoad, config);
        const result = await request?.data;
        if (result?.success) {
            if (result?.data?.["deposit-status-by-id"] === null || result?.data?.["deposit-status-by-id"] === '') {
                if (showDepositError){
                    navigate('/deposit?paymentStatus=failed'); 
                }else{
                    navigate('/deposit');
                }  
            } else if (result?.data?.["deposit-status-by-id"]?.toLowerCase() === "success"){
                navigate('/tax?paymentStatus=success');
            } else if (result?.data?.["deposit-status-by-id"]?.toLowerCase() === "manual review") {
                navigate('/tax?paymentStatus=manual-review');
            } else if (result?.data?.["deposit-status-by-id"]?.toLowerCase() === "unsuccessful") {
                navigate('/deposit?paymentStatus=failed');
            }
        }
    } catch (error) {
        console.log(error);
    }
}

