import "./style.scss";
import { fxcmLoader } from "../../constants/ImageConstants";

const Loader = () => {
	return (
		<div className="loader-class">
			<div className="loader">
				<img className="loader-logo" src={fxcmLoader} alt="loaderLogo" />
			</div>
		</div>
	);
};

export default Loader;
